import PropTypes from 'prop-types';

const ToastPropType = {
  type: PropTypes.oneOf(['alert', 'information', 'success', 'warning'])
    .isRequired,
  title: PropTypes.string,
  titleWithParams: PropTypes.shape({
    key: PropTypes.string,
    params: PropTypes.objectOf(PropTypes.string),
  }),
  description: PropTypes.string,
  descriptionWithParams: PropTypes.shape({
    key: PropTypes.string,
    params: PropTypes.objectOf(PropTypes.string),
  }),
  descriptionNodeWithProps: PropTypes.shape({
    node: PropTypes.ReactNodeLike,
    props: PropTypes.objectOf(PropTypes.any),
  }),
};

export { ToastPropType };
