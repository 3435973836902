import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useDropdownOptions = (options) => {
  const { t } = useTranslation('main');
  return useMemo(
    () =>
      options.map((option) => ({
        ...option,
        label: t(option.label),
        key: option.value,
      })),
    [options, t],
  );
};

export const useDropdownOnChange = (onChange) =>
  useCallback(([value]) => onChange(value), [onChange]);

export const useDropdownValue = (value) => useMemo(() => [value], [value]);
