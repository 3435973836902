import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import emptyImg from './empty-locale.png';
import Text from 'components/Text';
import Spacer from 'components/Spacer';

const Wrapper = styled.div`
  padding: 1.5rem;
  text-align: center;
`;
const Image = styled.img`
  width: 100%;
  max-width: 384px;
`;
const EmptyLocale = () => {
  const { t } = useTranslation('main');

  return (
    <Wrapper>
      <Image src={emptyImg} alt="No data" />
      <Spacer y="1rem" />
      <Text fontType="Heading" color="INK_600">
        {t('EmptyLocale - title')}
      </Text>
      <Spacer y="0.25rem" />
      <Text fontType="Body" color="INK_600">
        {t('EmptyLocale - subtitle')}
      </Text>
    </Wrapper>
  );
};

export default EmptyLocale;
