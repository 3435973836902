import { useCallback } from 'react';
import { Input as BaseInput } from '@shopline/dashboard-ui';

const Input = ({ onChange, ...props }) => {
  const handleChange = useCallback(
    ({ target: { value } }) => {
      if (typeof onChange === 'function') {
        onChange(value);
      }
    },
    [onChange],
  );
  return <BaseInput {...props} onChange={handleChange} />;
};

export default Input;
