import { useState, useCallback, useMemo, useRef } from 'react';
import { negate, identity } from 'lodash-es';
import { Dropdown as UIDropdown } from '@shopline/dashboard-ui';
import { useTranslation } from 'react-i18next';

import { DropdownPropTypes } from './types';
import TooltipSelectorWrapper from './TooltipSelectorWrapper';

const Dropdown = ({
  disabled = false,
  onChange,
  value,
  options,
  placeHolder,
  type = 'singleOption',
  menuAlign = 'left',
  shouldShowTooltip = false,
  tooltipDirection,
  keepPlaceHolder = false,
  ...otherProps
}) => {
  const innerRef = useRef(null);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionChange = (optionValues) => {
    onChange(optionValues);
    if (type === 'singleOption') handleDropdownClose();
  };

  const handleDropdownClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const onDropdownClick = useCallback(() => {
    if (disabled) return;
    setIsOpen(negate(identity));
  }, [disabled]);

  const noResultOption = useMemo(
    () => ({
      key: 'No Results',
      value: 'No Results',
      label: t('No Results'),
    }),
    [t],
  );

  const handleValueToLabel = useCallback(
    (optionValue) => {
      const currentOption = options.find(
        (option) => option.value === optionValue,
      );
      if (currentOption) {
        return `${currentOption.label}`;
      }
      return '';
    },
    [options],
  );

  const currentLabel = useMemo(
    () =>
      !value.length || keepPlaceHolder
        ? placeHolder
        : value.map((selected) => handleValueToLabel(selected)).join(','),
    [handleValueToLabel, value, keepPlaceHolder, placeHolder],
  );

  const renderTooltipSelector = ({ renderDefaultSelector }) => (
    <TooltipSelectorWrapper
      width={otherProps.width}
      dropdownRef={innerRef}
      tooltipDirection={tooltipDirection}
      text={currentLabel}
    >
      {renderDefaultSelector()}
    </TooltipSelectorWrapper>
  );

  return (
    <UIDropdown
      disabled={disabled}
      ref={innerRef}
      type={type}
      options={options}
      isOpen={isOpen}
      value={value}
      placeHolder={placeHolder}
      onClick={onDropdownClick}
      onClose={handleDropdownClose}
      onChange={handleOptionChange}
      menuAlign={menuAlign}
      keepPlaceHolder={keepPlaceHolder}
      optionPlaceholder={noResultOption}
      {...(shouldShowTooltip && { renderSelector: renderTooltipSelector })}
      {...otherProps}
    />
  );
};

Dropdown.propTypes = DropdownPropTypes;

export default Dropdown;
export { default as LoadingDropdownSelector } from './LoadingDropdownSelector';
