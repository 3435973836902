import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from 'components';
import PostMessageContext from 'contexts/PostMessage';
import { useQueryInitialState } from 'hooks/useInitialState';

const AppBase = ({ children }) => {
  const { i18n } = useTranslation();
  const [{ languageChange }] = useContext(PostMessageContext);
  const { isSuccess } = useQueryInitialState();

  useEffect(() => {
    if (languageChange) {
      i18n.changeLanguage(languageChange);
    }
  }, [i18n, languageChange]);

  if (!isSuccess) {
    return <Loading />;
  }
  return children;
};

export default AppBase;
