import PropTypes from 'prop-types';
import { uniqueId } from 'lodash-es';

import { ToastPropType } from 'types/toast';

import {
  CREATE_UI_TOAST,
  DISMISS_UI_TOAST,
  DELETE_UI_TOAST,
  CLEAR_UI_TOAST,
} from './actionTypes';

export const createUIToast = (toast) => {
  PropTypes.checkPropTypes(
    ToastPropType,
    toast,
    '[toast settings]',
    'showUIToast Action',
  );
  return {
    type: CREATE_UI_TOAST,
    payload: {
      id: uniqueId(),
      ...toast,
      isShow: true,
    },
  };
};

export const dismissUIToast = (id) => ({
  type: DISMISS_UI_TOAST,
  payload: { id },
});

export const deleteUIToast = (id) => ({
  type: DELETE_UI_TOAST,
  payload: { id },
});

export const clearUIToast = () => ({
  type: CLEAR_UI_TOAST,
});
