import styled from 'styled-components';

const StyledHr = styled.hr`
  width: 100%;
  border-width: 0;
  border-style: solid;
  border-color: ${({ theme, color }) => theme.colors[color]};
  border-bottom-width: 1px;
  margin-top: ${({ spacing }) => spacing};
  margin-bottom: ${({ spacing }) => spacing};
`;

const Divider = ({ color = 'INK_300', spacing = '1rem' }) => {
  return <StyledHr color={color} spacing={spacing} />;
};

export default Divider;
