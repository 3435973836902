import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import prop from 'lodash/fp/prop';
import { media } from '@shopline/dashboard-ui';
import Text from 'components/Text';
import { withProps } from 'utils/recompose';

const Container = styled.section`
  margin-top: 1rem;
  padding: 1.5rem;
  background-color: ${prop('theme.colors.SNOW_DEFAULT')};
  box-shadow: 0px 0px 1px ${prop('theme.colors.SHADOW_DEFAULT')},
    0px 1px 4px ${prop('theme.colors.SHADOW_LIGHT')};
  border-radius: 6px;
  ${media.mobile`
    margin-top: 0.5rem;
  `}
  &:first-child {
    margin-top: 0;
  }
`;

export const Subtitle = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const CaptionText = withProps(({ color }) => ({
  as: 'div',
  fontType: 'Body',
  color: color || 'INK_600',
}))(Text);

export const Caption = styled(CaptionText)`
  flex: 0 0 100%;
  margin-top: 0.25rem;
`;

const Section = forwardRef(({ title, children, ...props }, ref) => (
  <Container {...props} ref={ref}>
    <Text as="h3" fontType="DisplaySmall" color="INK_800">
      {title}
    </Text>
    {children}
  </Container>
));
Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Section;
