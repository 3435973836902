import { every, get } from 'lodash-es';
import { useQueryInitialState } from './useInitialState';

export default function usePermissions(scope, action) {
  const {
    isSuccess,
    data: { permissions },
  } = useQueryInitialState();
  if (!isSuccess) {
    return false;
  }
  if (Array.isArray(scope)) {
    return every(scope, (pair) => get(permissions, pair, false));
  }
  return get(permissions, [scope, action], false);
}
