import { createContext, useReducer, useMemo } from 'react';
import { mapValues } from 'lodash-es';
import reducer, { initialState } from './reducer';
import * as actions from './actions';

const Context = createContext();
const StateContext = createContext();

const Provider = ({ state: defaultState = initialState, children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  const memoizedActions = useMemo(
    () =>
      mapValues(
        actions,
        (action) =>
          (...args) =>
            dispatch(action(...args)),
      ),
    [dispatch],
  );
  return (
    <Context.Provider value={memoizedActions}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </Context.Provider>
  );
};

export default Context;
export { Provider, StateContext };
