import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { nanoid } from 'nanoid';
import useSimpleForm from 'hooks/useSimpleForm';
import ToastContext from 'contexts/Toast';
import { useQueryAvailableRegions } from './useAvailableRegions';
import { useQueryAvailableLocales } from './useAvailableLocales';
import { useCreateRegionAndLocalesSet } from './useRegionAndLocaleSets';

const noRules = [];

const useRegionAndLocaleSetForm = () => {
  const { createUIToast } = useContext(ToastContext);
  const onSuccess = useCallback(() => {
    createUIToast({
      id: nanoid(),
      type: 'success',
      titleWithParams: {
        key: 'main:Successfully set the Region and Language for Ads Delivery',
      },
    });
  }, [createUIToast]);
  const { isSuccess: isRegionsSuccess, data: regions } =
    useQueryAvailableRegions();
  const { isSuccess: isLocaleSuccess, data: locales } =
    useQueryAvailableLocales();
  const defaultValuesRef = useRef({
    region: regions[0]?.value || '',
    locale: locales[0]?.value || '',
  });
  const { mutate, isLoading: submitting } = useCreateRegionAndLocalesSet({
    onSuccess,
  });
  const [values, meta] = useSimpleForm({
    defaultValues: defaultValuesRef.current,
    rules: noRules,
    onSubmit: mutate,
  });
  const { setValues } = meta;

  const renderNothing = useMemo(() => [], []);

  useEffect(() => {
    if (!isRegionsSuccess) {
      return;
    }
    if (!isLocaleSuccess) {
      return;
    }
    if (!regions[0]?.value) {
      return;
    }
    if (!locales[0]?.value) {
      return;
    }
    defaultValuesRef.current = {
      region: regions[0]?.value || '',
      locale: locales[0]?.value || '',
    };
    setValues(defaultValuesRef.current);
  }, [isRegionsSuccess, regions, isLocaleSuccess, locales, setValues]);

  return [
    values,
    meta,
    {
      submitting,
      renderMessages: {
        region: renderNothing,
        locale: renderNothing,
      },
    },
  ];
};

export default useRegionAndLocaleSetForm;
