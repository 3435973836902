import axios from 'axios';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const getAvailableRegions = async (merchantId) => {
  const { data } = await axios.get(
    `/api/merchants/${merchantId}/available-regions`,
  );
  return data.data;
};

const composeQueryKey = (merchantId) => [
  'merchant-available-regions',
  merchantId,
];

const passMerchantId =
  (queryFn) =>
  ({ queryKey: [_, merchantId] }) =>
    queryFn(merchantId);

export const useQueryAvailableRegions = (options) => {
  const { merchantId } = useParams();
  return useQuery(
    composeQueryKey(merchantId),
    passMerchantId(getAvailableRegions),
    {
      placeholderData: [],
      ...options,
    },
  );
};
