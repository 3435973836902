import { createContext, useEffect, useState, useMemo, useContext } from 'react';
import update from 'immutability-helper';
import identity from 'lodash/identity';
import curryRight from 'lodash/curryRight';
import mapValues from 'lodash/fp/mapValues';
import getOr from 'lodash/fp/getOr';

const curriedUpdate = curryRight(update, 2);

const dispatchMessage = (payload) => {
  if (window.parent === window) {
    // eslint-disable-next-line no-console
    console.log('cannot request to parent window', payload);
    return;
  }
  window.parent.postMessage(payload, '*');
};

const PostMessageContext = createContext();

const createInitialState = mapValues(getOr(null, ['initialValue']));

const createHandlers = mapValues(
  ({ dispatch }) =>
    (...args) =>
      dispatchMessage(dispatch(...args)),
);

const createUpdater = curryRight(({ data }, config) =>
  mapValues(
    ({ parse }) =>
      typeof parse === 'function' ? parse(data) : { $apply: identity },
    config,
  ),
);

export const PostMessageProvider = ({ config, children }) => {
  const [state, setState] = useState(createInitialState(config));
  const handlers = useMemo(() => createHandlers(config), [config]);

  useEffect(() => {
    const handleMessage = (event) => {
      setState(curriedUpdate(createUpdater(event, config)));
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  });
  return (
    <PostMessageContext.Provider value={[state, handlers]}>
      {children}
    </PostMessageContext.Provider>
  );
};

export const usePostMessageContext = () => useContext(PostMessageContext);

export default PostMessageContext;
