import { prop } from 'lodash/fp';
import { useRef } from 'react';
import styled from 'styled-components';

import useBoundingClientRect from 'hooks/useBoundinClientRect';
import useSticky from 'hooks/useSticky';

const Wrapper = styled.div`
  position: ${({ isFixed }) => (isFixed ? 'fixed' : 'static')};
  top: ${prop('top')}px;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
`;

const StickyWrapper = ({ top = 0, children, className }) => {
  const ref = useRef(null);
  // to lock width when change to position fixed
  const { width: originalWidth } = useBoundingClientRect(ref);
  const shouldStick = useSticky(ref, top);

  return (
    <Wrapper
      ref={ref}
      isFixed={shouldStick}
      top={top}
      width={originalWidth}
      className={className}
    >
      {children}
    </Wrapper>
  );
};

export default StickyWrapper;
