import { useWindowScroll } from 'react-use';
import { isNil } from 'lodash';

import useBoundingClientRect from './useBoundinClientRect';

const useSticky = (ref, top = 0) => {
  const { y: elementY } = useBoundingClientRect(ref);
  const { y: scrollY } = useWindowScroll();

  if (isNil(elementY) || elementY === 0 || isNil(scrollY)) {
    return false;
  }

  return elementY - top <= scrollY;
};

export default useSticky;
