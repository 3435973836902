import { useEffect } from 'react';

import { usePostMessageContext } from 'contexts/PostMessage';

const useAdminHost = () => {
  const [{ requestAdminHost: adminHost }, { requestAdminHost }] =
    usePostMessageContext();

  useEffect(() => {
    if (!adminHost) {
      requestAdminHost();
    }
  }, [adminHost, requestAdminHost]);

  return adminHost;
};

export default useAdminHost;
