import { useTranslation } from 'react-i18next';
import { AnchorLink, Icons } from '@shopline/dashboard-ui';
import Spacer from 'components/Spacer';
import { Grid, Page, PageTitle, Section, SectionCaption } from './components';
import StickyWrapper from 'components/StickyWrapper';
import useNav from './useNav';
import usePermissions from 'hooks/usePermissions';
import { useQueryMerchant } from './useMerchant';
import { useQueryRegionAndLocalesSets } from './useRegionAndLocaleSets';
import withLoading from './withLoading';
import MerchantForm from './MerchantForm';
import RegionAndLocaleSets from './RegionAndLocaleSets';
import PARENT_E2E_PREFIX from './e2ePrefix';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-index`;

const EnhancedMerchantForm = withLoading(MerchantForm);
const EnhancedRegionAndLocaleSets = withLoading(RegionAndLocaleSets);

const Main = () => {
  const { t } = useTranslation(['main', 'pageTitle']);
  const {
    items,
    refs: { formSectionRef, listSectionRef },
  } = useNav({ e2ePrefix: `${E2E_PREFIX}-use-nav` });

  const canQuery = usePermissions([
    ['merchant', 'show'],
    ['regionLocaleSet', 'index'],
  ]);
  const { isSuccess: isMerchantLoaded } = useQueryMerchant();
  const { isSuccess: isRegionAndLocalesSetsLoaded } =
    useQueryRegionAndLocalesSets();

  /* eslint-disable jsx-a11y/anchor-has-content */
  return (
    <Page
      titleLeftSection={
        <PageTitle>
          {t('Google Merchant Center', { ns: 'pageTitle' })}
          <Spacer x="0.25rem" />
          <a href={t('App support link')} target="_blank" rel="noreferrer">
            <Icons.Outline.QuestionMark size="MEDIUM" color="INK_400" />
          </a>
        </PageTitle>
      }
    >
      {canQuery && (
        <Grid.Row>
          <Grid.Column disableGutter lg={8} md={8} sd={12}>
            <Section title={t('Config GMC')} ref={formSectionRef}>
              <SectionCaption>{t('Config GMC Caption')}</SectionCaption>
              <EnhancedMerchantForm loading={!isMerchantLoaded} />
            </Section>
            <Section title={t('Config region & locale')} ref={listSectionRef}>
              <SectionCaption>
                {t('Config region & locale Caption')}
              </SectionCaption>
              <EnhancedRegionAndLocaleSets
                loading={!isRegionAndLocalesSetsLoaded}
              />
            </Section>
          </Grid.Column>
          <Grid.Column disableGutter lg={4} md={4} sd={12}>
            <StickyWrapper top={20}>
              <AnchorLink
                scrollbarWrapper={document.documentElement}
                items={items}
              />
            </StickyWrapper>
          </Grid.Column>
        </Grid.Row>
      )}
    </Page>
  );
  /* eslint-enable jsx-a11y/anchor-has-content */
};

export default Main;
