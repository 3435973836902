import { ThemeProvider } from 'styled-components';
import { Theme } from '@shopline/dashboard-ui';
import { QueryClientProvider } from 'react-query';

import 'locales';

import GlobalStyle from 'components/GlobalStyle';

import { PostMessageProvider } from 'contexts/PostMessage';
import { Provider as ToastProvider } from 'contexts/Toast';
import queryClient from './queryClient';
import postMessageConfig from './postMessageConfig';

import FeatureRoute from './FeatureRoute';

const Features = () => (
  <PostMessageProvider config={postMessageConfig}>
    <ToastProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={Theme}>
          <GlobalStyle />
          <FeatureRoute />
        </ThemeProvider>
      </QueryClientProvider>
    </ToastProvider>
  </PostMessageProvider>
);

export default Features;
