const main = {
  'App support link':
    'https://support.shoplineapp.com/hc/en-us/articles/23966890906649',
  'Config GMC':
    'Upload product information to Google Merchant Center using Content API',
  'Config GMC Caption':
    'Through a Content API connection, your products will be automatically uploaded to Google Merchant Center, allowing them to be accessible in Google Ads, YouTube Live Shopping, and other Google features.',
  'Merchant Center ID': 'Merchant Center ID',
  'Merchant Center ID - description_unintegrated':
    'Your shop is not yet connected with a verified Google Merchant Center account.\nGo to Settings > Basic settings > Third Party Domain Tools > Google Merchat Center to set up connection',
  'Merchant Center ID - description_integrated':
    'Once you enable Content API, your product information will be shared with the verified Google Merchant Center account shown below.',
  'Merchant Center ID - action button_unintegrated': 'Setup Now',
  'Merchant Center ID - action button_integrated': 'See Settings',
  'Preorder Period': 'Availability Date',
  'Merchant has been saved successfully':
    'Settings have been saved successfully',
  'Failed to save merchant': 'Failed to save merchant',
  'Successfully set the Region and Language for Ads Delivery':
    'Successfully set the Region and Language for Ads Delivery',
  'Please enter numbers only': 'Please enter numbers only',
  'Upload products to GMC': 'Upload products to GMC',
  'Upload products to GMC - description':
    'Please be sure to copy and add the following link to Google Merchant Center to successfully use the Content API to instantly upload products to GMC.',
  'Upload products to GMC - step 1':
    '1. Click the icon on the right to copy Email',
  'Upload products to GMC - step 2':
    '2. Go to Google Merchant Center > Tools and Settings (click the middle gear icon in the upper right corner) > Account Access > Users',
  'Upload products to GMC - step 3':
    '3. Add a new user and share administrator rights to the user',
  'Save before leaving': '*Please save any changes before leaving the page.',
  'Authorize Content API Title': 'Authorize Content API service account access',
  'Authorize Content API Description':
    'Please follow the <1>FAQ</1> instructions, go to Google Merchant Center to create Service Account Credentials & download the json key file.',
  'Content API FAQ Link':
    'https://support.shoplineapp.com/hc/en-us/articles/23966890906649',
  'Upload Files Here': 'Upload Files Here',
  'Invalid Credential File':
    'Credential file is not valid, please check or create a new credential key.',

  'Config region & locale': 'Placement Area and Placement Language Settings',
  'Config region & locale Caption':
    'The system will upload your products (including preorder and hidden products) to Google Merchant Center. Product ads will be displayed based on the selected placement region and language.',
  Region: 'Placement Area',
  Locale: 'Placement Language',
  'Add new languages to the list below': 'Add new languages to the list below',
  'EmptyLocale - title': 'There’s no data yet',
  'EmptyLocale - subtitle': 'Add a language from above settings',
  DATA_TABLE: {
    REGION: 'Placement Area',
    LOCALE: 'Placement Language',
    ACTION: 'Turn on/off',
  },
  LOCALE: {
    'zh-hant': 'Traditional Chinese',
    'zh-tw': 'Traditional Chinese',
    'zh-hk': 'Traditional Chinese',
    'zh-cn': 'Simplified Chinese',
    en: 'English',
    ms: 'Malay',
    th: 'Thai',
    vi: 'Vietnamese',
    id: 'Indonesian',
    de: 'German',
    fr: 'French',
    ja: 'Japanese',
  },
  REGION: {
    TW: 'Taiwan',
    CN: 'China',
    HK: 'Hong Kong',
    SG: 'Singapore',
    MY: 'Malaysia',
    TH: 'Thailand',
    VN: 'Vietnam',
    PH: 'Philippines',
    US: 'United States',
    AU: 'Australia',
    GB: 'United Kingdom',
  },
  UNIT: {
    days: 'days',
  },
};

export default main;
