import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import prop from 'lodash/fp/prop';
import { Button, Toggle } from '@shopline/dashboard-ui';
import usePermissions from 'hooks/usePermissions';
import {
  useQueryRegionAndLocalesSets,
  useRemoveRegionAndLocalesSet,
  useUpdateRegionAndLocalesSet,
} from './useRegionAndLocaleSets';

const KEY_PREFIX = 'DATA_TABLE';
const KEYS = {
  REGION: `${KEY_PREFIX}.REGION`,
  LOCALE: `${KEY_PREFIX}.LOCALE`,
  ACTION: `${KEY_PREFIX}.ACTION`,
};
const COLUMNS = [
  { key: KEYS.REGION },
  { key: KEYS.LOCALE },
  { key: KEYS.ACTION },
];

const dataSourceSelector = createStructuredSelector({
  id: prop('id'),
  enabled: prop(['enabled']),
  [KEYS.REGION]: prop(['region']),
  [KEYS.LOCALE]: prop(['locale']),
});

const useDataTable = () => {
  const { t } = useTranslation('main', 'common');
  const canPatch = usePermissions('regionLocaleSet', 'patch');
  const { data } = useQueryRegionAndLocalesSets();
  const { mutate: removeSet, isLoading: submitting } =
    useRemoveRegionAndLocalesSet();
  const { mutate: updateSet, isLoading: updating } =
    useUpdateRegionAndLocalesSet();
  const renderActionFactory = useCallback(
    ({ enabled }) =>
      () =>
        (
          <>
            {false && (
              <Button.Outline
                buttonKey="destructive"
                width="NORMAL"
                height="SMALL"
                data-btn-action="delete-region-locale-set"
              >
                {t('Remove', { ns: 'common' })}
              </Button.Outline>
            )}
            <Toggle
              isToggledOn={enabled}
              disabled={!canPatch || updating}
              data-btn-action="toggle-region-locale-set-enabled"
            />
          </>
        ),
    [t, updating, canPatch],
  );
  const handleTableRowClick = useCallback(
    (event, { id: setId, enabled }) => {
      if (!canPatch) {
        return;
      }
      if (
        event.target.closest(
          '[data-btn-action=toggle-region-locale-set-enabled]',
        )
      ) {
        updateSet({
          setId,
          value: { enabled: !enabled },
        });
      }
      if (event.target.closest('[data-btn-action=delete-region-locale-set]')) {
        removeSet(setId);
      }
    },
    [canPatch, removeSet, updateSet],
  );
  return useMemo(
    () => ({
      handleTableRowClick,
      columns: COLUMNS.map((column) => ({
        ...column,
        align: 'center',
        title: t(column.key),
      })),
      dataSources: Array.isArray(data)
        ? data.map(dataSourceSelector).map((source) => ({
            ...source,
            [KEYS.REGION]: t(`REGION.${source[KEYS.REGION]}`),
            [KEYS.LOCALE]: t(`LOCALE.${source[KEYS.LOCALE]}`),
            [KEYS.ACTION]: renderActionFactory(source),
          }))
        : [],
    }),
    [t, data, renderActionFactory, handleTableRowClick],
  );
};

export default useDataTable;
