import { has, omit } from 'lodash-es';
import {
  CREATE_UI_TOAST,
  DISMISS_UI_TOAST,
  DELETE_UI_TOAST,
  CLEAR_UI_TOAST,
} from './actionTypes';

export const initialState = {};

const reducer = (state, action) => {
  switch (action.type) {
    case CREATE_UI_TOAST:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };

    case DISMISS_UI_TOAST:
      return has(state, action.payload.id)
        ? {
            ...state,
            [action.payload.id]: {
              ...state[action.payload.id],
              isShow: false,
            },
          }
        : state;
    case DELETE_UI_TOAST:
      return omit(state, [action.payload.id]);

    case CLEAR_UI_TOAST:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
