// export type fallbackFunc<T> = (value?: string) => T;

// export function env(key: EnvKey): string;
// export function env(key: EnvKey, fallback: string): string;
// export function env<T>(key: EnvKey, fallback: fallbackFunc<T>): T;

export function env(key, fallback) {
  const value = window._env_[key] ?? process.env[key];
  if (typeof fallback === 'function') {
    return fallback(value);
  }
  return value ?? fallback;
}
