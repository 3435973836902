import { Children } from 'react';
import styled from 'styled-components';
import { Text } from 'components';

const Container = styled.div`
  margin: -1rem 0 1rem;
`;

const StyledText = styled(Text).attrs({
  fontType: 'Body',
  color: 'INK_600',
})`
  &:not(:first-child) {
    margin-top: 0.5rem;
  }
`;

const PageDescription = ({ children }) => (
  <Container>
    {Children.map(children, (child) => (
      <StyledText>{child}</StyledText>
    ))}
  </Container>
);

export default PageDescription;
