import styled from 'styled-components';
import { LoadingSpinner } from '@shopline/dashboard-ui';

const LoadingWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loading = () => (
  <LoadingWrapper>
    <LoadingSpinner size="36" />
  </LoadingWrapper>
);

export default Loading;
