import { LoadingDropdownSelector } from 'components';
import { Dropdown } from './components';
import { useQueryAvailableRegions } from './useAvailableRegions';
import { useDropdownOptions, useDropdownOnChange } from './useDropdown';
import PARENT_E2E_PREFIX from './e2ePrefix';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-region-dropdown`;

const RegionDropdown = ({ onChange, value }) => {
  const { isSuccess, data } = useQueryAvailableRegions();
  const options = useDropdownOptions(
    data.map((item) => ({
      ...item,
      label: `REGION.${item.value}`,
    })),
  );
  const handleChange = useDropdownOnChange(onChange);
  return (
    <Dropdown
      onChange={handleChange}
      value={value}
      options={options}
      renderSelector={
        isSuccess
          ? undefined
          : () => <LoadingDropdownSelector>Loading</LoadingDropdownSelector>
      }
      placeHolder=""
      optionMaxHight="15.75rem;"
      e2eId={E2E_PREFIX}
    />
  );
};

export default RegionDropdown;
