const main = {
  'App support link':
    'https://support.shoplineapp.com/hc/zh-tw/articles/23966890906649',
  'Config GMC': '使用 Content API 上傳商品資料到 Google Merchant Center',
  'Config GMC Caption':
    '透過串接 Content API ，商品資料能夠即時自動更新至 Google Merchant Center，並能應用於 Google 廣告、YouTube Live Shopping 購物功能及其他 Google 服務。',
  'Merchant Center ID': 'Merchant Center ID',
  'Merchant Center ID - description_unintegrated':
    '你的網店尚未與任何 Google Merchant Center 帳戶串接並完成驗證，請前往 基本資料 > 第三方網域工具 > Google Merchant Center 串連',
  'Merchant Center ID - description_integrated':
    '啟用 Content API 後，將會傳送產品資料至以下驗證完成的 Google Merchant Center 帳戶。',
  'Merchant Center ID - action button_unintegrated': '前往串接',
  'Merchant Center ID - action button_integrated': '查看設定',
  'Preorder Period': '出貨遞延天數',
  'Merchant has been saved successfully': '商家設定已成功保存',
  'Failed to save merchant': '商家設定保存失敗',
  'Successfully set the Region and Language for Ads Delivery':
    '成功設定廣告投放地區及語系',
  'Please enter numbers only': '僅限輸入數字',
  'Upload products to GMC': '上傳商品至 GMC 功能',
  'Upload products to GMC - description':
    '請務必將以下連結複製並加入 Google Merchant Center，方能成功使用 Content API 即時上傳商品至 GMC 功能',
  'Upload products to GMC - step 1': '1. 點擊右邊 icon 複製 Email',
  'Upload products to GMC - step 2':
    '2. 進入 Google Merchant Center > 工具與設定（右上角偏中間齒輪 icon 點入） > 帳戶存取權 > 使用者',
  'Upload products to GMC - step 3': '3. 新增使用者並 Share 管理員權限至使用者',
  'Save before leaving': '*請注意，在離開此頁之前，請先儲存內容。',
  'Authorize Content API Title': '授權 Content API 服務帳戶使用權限',
  'Authorize Content API Description':
    '請依照 <1>FAQ</1> 指示，前往 Google Merchant Center 後台下載 Content API 服務帳號憑證，並將憑證檔案上傳於此',
  'Content API FAQ Link':
    'https://support.shoplineapp.com/hc/zh-tw/articles/23966890906649',
  'Upload Files Here': '上傳檔案',
  'Invalid Credential File': '檔案無效，請檢查或更新你的憑證檔案',

  'Config region & locale': '投放地區及語系設定',
  'Config region & locale Caption':
    '系統將您的商品（包含預購、隱藏商品）上傳至 Google Merchant Center 後，會根據您設定的投放地區及語系展示您的商品廣告。',
  Region: '投放地區',
  Locale: '投放語系',
  'Add new languages to the list below': '新增語系到下面列表',
  'EmptyLocale - title': '還沒有數據',
  'EmptyLocale - subtitle': '從上面的設置中添加語言',
  DATA_TABLE: {
    REGION: '地區',
    LOCALE: '語系',
    ACTION: '開啟/關閉',
  },
  LOCALE: {
    'zh-hant': '繁體中文',
    'zh-tw': '繁體中文',
    'zh-hk': '繁體中文',
    'zh-cn': '簡體中文',
    en: '英文',
    ms: '馬來文',
    th: '泰文',
    vi: '越南文',
    id: '印尼文',
    de: '德文',
    fr: '法文',
    ja: '日文',
  },
  REGION: {
    TW: '台灣',
    CN: '中國',
    HK: '香港',
    SG: '新加坡',
    MY: '馬來西亞',
    TH: '泰國',
    VN: '越南',
    PH: '菲律賓',
    US: '美國',
    AU: '澳洲',
    GB: '英國',
  },
  UNIT: {
    days: '天',
  },
};

export default main;
