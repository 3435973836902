import { Table } from '@shopline/dashboard-ui';
import { useTranslation } from 'react-i18next';
import usePermissions from 'hooks/usePermissions';
import { Field, FieldRow, FormFooter } from './components';
import RegionDropdown from './RegionDropdown';
import LocaleDropdown from './LocaleDropdown';
import { useDropdownValue } from './useDropdown';
import useRegionAndLocaleSetForm from './useRegionAndLocaleSetForm';
import useDataTable from './useDataTable';
import PARENT_E2E_PREFIX from './e2ePrefix';
import { Icons } from '@shopline/dashboard-ui';
import { TextButton } from '@shopline/dashboard-ui';
import Divider from 'components/Divider';
import EmptyLocale from './components/EmptyLocale';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-region-and-locale-sets`;

const RegionAndLocaleSets = () => {
  const { t } = useTranslation(['main', 'common']);
  const canCreate = usePermissions('regionLocaleSet', 'create');
  const [
    { region, locale },
    { handlers, handleSubmit, hasErrors },
    { submitting },
  ] = useRegionAndLocaleSetForm();
  const regionValue = useDropdownValue(region);
  const localeValue = useDropdownValue(locale);
  const { columns, dataSources, handleTableRowClick } = useDataTable();

  return (
    <>
      <FieldRow>
        <Field title={t('Region')}>
          <RegionDropdown value={regionValue} {...handlers.region} />
        </Field>
        <Field title={t('Locale')}>
          <LocaleDropdown value={localeValue} {...handlers.locale} />
        </Field>
      </FieldRow>
      <FormFooter>
        <TextButton.Primary
          width="WIDE"
          e2eId={`${E2E_PREFIX}-add-btn`}
          onClick={handleSubmit}
          disabled={!canCreate || hasErrors || submitting}
        >
          <Icons.Outline.Plus size="SMALL" />
          {t('Add new languages to the list below')}
        </TextButton.Primary>
      </FormFooter>
      <Divider spacing="2rem" />

      {dataSources.length === 0 ? (
        <EmptyLocale />
      ) : (
        <Table
          headColumnAlign="center"
          rowVerticalAlign="middle"
          columns={columns}
          dataSource={dataSources}
          onTableRowClick={handleTableRowClick}
        />
      )}
    </>
  );
};

export default RegionAndLocaleSets;
