import { useState, useCallback, useMemo, useEffect } from 'react';
import update from 'immutability-helper';
import { curryRight, mapValues, transform } from 'lodash-es';
import validate from 'utils/validate';

const curriedUpdate = curryRight(update, 2);

const getFieldError = (field, value, rules) => {
  const fieldRule = rules[field];
  if (!fieldRule) {
    return '';
  }
  return validate(value, fieldRule);
};

const useSimpleForm = ({
  defaultValues,
  rules,
  validateDefaultValues,
  onSubmit,
}) => {
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState(mapValues(defaultValues, () => ''));
  const [everFulfilled, setEverFulfilled] = useState(
    mapValues(defaultValues, () => false),
  );
  const [touched, setTouched] = useState(false);
  const handlers = useMemo(
    () =>
      mapValues(defaultValues, (_, field) => ({
        onChange: (value) => {
          setValues(
            curriedUpdate({
              [field]: {
                $set: value,
              },
            }),
          );
          const error = getFieldError(field, value, rules);
          if (error === '' && !everFulfilled[field]) {
            setEverFulfilled(
              curriedUpdate({
                [field]: {
                  $set: true,
                },
              }),
            );
          }
          if (everFulfilled[field]) {
            setErrors(
              curriedUpdate({
                [field]: {
                  $set: error,
                },
              }),
            );
          }
        },
        onFocus: () => {
          setTouched(true);
        },
        onBlur: ({ target: { value } }) => {
          const error = getFieldError(field, value, rules);
          setErrors(
            curriedUpdate({
              [field]: {
                $set: error,
              },
            }),
          );
        },
      })),
    [rules, defaultValues, everFulfilled],
  );
  const { hasErrors } = transform(
    errors,
    (result, value, key) => {
      if (!result.hasErrors) {
        result.hasErrors = value !== '';
      }
    },
    { hasErrors: false },
  );

  const handleSubmit = useCallback(() => {
    onSubmit(values);
    setTouched(false);
  }, [onSubmit, setTouched, values]);

  useEffect(() => {
    setTouched(false);
    if (validateDefaultValues) {
      setErrors(
        mapValues(defaultValues, (value, field) =>
          getFieldError(field, value, rules),
        ),
      );
    } else {
      setErrors(mapValues(defaultValues, () => ''));
    }
  }, [validateDefaultValues, defaultValues, rules]);

  return [
    values,
    { errors, handlers, setValues, handleSubmit, hasErrors, touched },
  ];
};

export default useSimpleForm;
