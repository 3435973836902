import { useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useNav = ({ e2ePrefix }) => {
  const { t } = useTranslation('main');
  const formSectionRef = useRef();
  const listSectionRef = useRef();
  const items = useMemo(
    () => [
      {
        title: t('Config GMC'),
        e2eId: `${e2ePrefix}-config-gmc`,
        target: formSectionRef,
        content: t('Config GMC'),
      },
      {
        title: t('Config region & locale'),
        e2eId: `${e2ePrefix}-config-region-and-locale`,
        target: listSectionRef,
        content: t('Config region & locale'),
      },
    ],
    [t, e2ePrefix],
  );

  return {
    refs: {
      formSectionRef,
      listSectionRef,
    },
    items,
  };
};

export default useNav;
