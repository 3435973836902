import PropTypes from 'prop-types';

const OptionPropTypes = {
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  iconColor: PropTypes.string,
  border: PropTypes.string,
  e2eId: PropTypes.string,
  iconDirection: PropTypes.oneOf(['left', 'right', 'center']),
};

const DropdownPropTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape(OptionPropTypes)).isRequired,
  placeHolder: PropTypes.string.isRequired,
  placeHolderColor: PropTypes.string,
  block: PropTypes.bool,
  inputValue: PropTypes.string,
  isOpen: PropTypes.bool,
  minHeight: PropTypes.string,
  optionMarginTop: PropTypes.string,
  optionMaxHight: PropTypes.string,
  disabledShadow: PropTypes.bool,
  contentPadding: PropTypes.string,
  border: PropTypes.string,
  useDefaultBorder: PropTypes.bool,
  keepPlaceHolder: PropTypes.bool,
  onInputChange: PropTypes.func,
  renderSelector: PropTypes.func,
  enableSearch: PropTypes.bool,
  optionFilterProp: PropTypes.arrayOf(['key', 'value', 'label']),
  filterOption: PropTypes.func,
  optionPlaceholder: PropTypes.arrayOf(OptionPropTypes),
  e2eId: PropTypes.string,
  disabled: PropTypes.bool,
  menuAlign: PropTypes.arrayOf(['left', 'right']),
  shouldShowTooltip: PropTypes.bool,
  tooltipDirection: PropTypes.string,
  dropdownRef: PropTypes.object,
};

export { OptionPropTypes, DropdownPropTypes };
